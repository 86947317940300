/* #sidebar {
    background: #ffffff;
    position: absolute;
    z-index: 999;
    top: 80px;
    right: 0;
    bottom: 0;
    width: 256px;
    border: 1px solid rgba(0,0,0,0.4);
    font-family: 'Open Sans', sans-serif;
}*/

#sidebar {
    display: flex;
    background: white;
    position: absolute;
    z-index: 999;
    top: 80px;
    right: 0;
    bottom: 0;
    width: 320px;
    flex-direction: column;
    align-items: flex-start;
}

#sidebar-header {
    width: 100%;
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: space-between;
}

.sidebar-link {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-link-button {
    height: 30px;
    width: 30px;
    background-size: cover;
    border: none;
    cursor: pointer;
}

.sidebar-hidden {
    position: absolute;
    z-index: 999;
    right: 10px;
    top: 90px;
}

#sidebar-content {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

#sidebar-content h1, #sidebar-content h2 {
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 3px;
}

#sidebar-content h3 {
    margin-left: 5px;
    margin-bottom: 3px;
    margin-top: 25px;
}

#sentinel-datetime-label {
    font-size: 16px;
    margin-bottom: 2px;
    color: black;
    vertical-align: middle;
    display: inline-block;
}

#datetime-input-sentinel {
    height: 35px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 17px;
    width: 273px;
}

#change-datetime-element-sentinel {
    margin-top: 65px;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

#datetime-input {
    height: 30px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
    padding-left: 5px;
    font-size: 15px;
}

#weather-controls {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin-right: 5px;
}

#astronomy-segment {
    margin-top: 5px;
    margin-bottom: 5px;
}

#sunburst-sunrise-sunset {
    font-size: 20px;
    color: black;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
}

#sunburst-sunrise-sunset-error {
    font-size: 15px;
    color: black;
    vertical-align: middle;
    display: inline-block;
    margin-left: 5px;
}

#sunrise-sunset-metrics {
    margin-left: 5px;
}

#sunburst-metrics {
    margin-left: 10px;
}

#sunburst-metrics text {
    font-size: 14px !important;
}

#sunburstquality {
    margin-right: 5px;
    margin-bottom: 2px;
}

#sunrise-sunset-metrics text {
    font-size: 16px;
    color: black;
    vertical-align: middle;
    display: inline-block;
}

#sunrise-sunset-metrics span {
    font-size: 20px;
    color: black;
    vertical-align: middle;
    display: inline-block;
}

#moon-phase-metrics {
    margin-left: 5px;
}

#moon-phase-metrics span {
    font-size: 20px;
    color: black;
    vertical-align: middle;
    display: inline-block;
}

#moon-phase-metrics text {
    font-size: 16px;
    color: black;
    vertical-align: middle;
    display: inline-block;
}

#sunburst-quality-info-button-hover {
    font-size: 14px !important;
    color: black;
    margin-left: 5px;
}

#sunburst-quality-info-button-hover span {
    display: inline-block;
    padding-top: 2px;
    padding-bottom: 2px;
}

.sunburst-quality-info-button-icon {
    width: 12px;
    height: 12px;
    margin-left: 5px;
    vertical-align: middle;
    display: inline-block;
}


.sunburst-sunrise-sunset-icon {
    width: 30px;
    height: 30px;
    margin-left: 0px;
    vertical-align: middle;
    display: inline-block;
}

.sunburst-clock-icon {
    width: 16px;
    height: 16px;
    margin-right: 3px;
    stroke: #000000;
    vertical-align: middle;
    display: inline-block;
    margin-top: 2px;
}

.moon-phase-icon {
    width: 25px;
    height: 25px;
    margin-left: 2px;
    margin-right: 3px;
    vertical-align: middle;
    display: inline-block;
}

.polling-icon {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-right: 3px;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
}
.polling-icon:hover {
    fill: #c0c0c0;
}

.clock-forward-icon {
    width: 29px;
    height: 29px;
    margin-left: 5px;
    margin-right: 3px;
    stroke: #000000;
    vertical-align: middle;
    display: inline-block;
}
.clock-forward-icon:hover {
    fill: #c0c0c0;
    stroke: #c0c0c0;
}

#change-polling-position-button span {
    font-size: 15px;
    color: black;
    vertical-align: middle;
    display: inline-block;
}

#set-datetime-to-now-button {
    margin-bottom: 5px;
}

#change-polling-position-button {
    margin-bottom: 5px;
}

#set-datetime-to-now-button span {
    font-size: 15px;
    color: black;
    vertical-align: middle;
    display: inline-block;
}

#base-layer-container {
    margin-left: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2%;
    width: 300px;
    user-select: none;
    margin-bottom: 80px;
}

.base-layer-item {
    aspect-ratio: 1/1;
    color: black;
    width: 120px;
    height: 90px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    font-size: 15px;
    border: 2px solid black;
}

.base-layer-selected {
    border: 2px solid rgb(0, 110, 200);
}

.base-layer-img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}

.base-layer-esri {
    background-image: url('../images/esri.png');    
}

.base-layer-googlehybrid {
    background-image: url('../images/googlehybrid.png');
}

.base-layer-binghybrid {
    background-image: url('../images/binghybrid.png');
}

.base-layer-openstreetmap {
    background-image: url('../images/openstreetmap.png');
}

.base-layer-naip {
    background-image: url('../images/naip.png');
}

.base-layer-vfr {
    background-image: url('../images/vfr.png');
}

.base-layer-maxar {
    background-image: url('../images/maxar.png');
}

.base-layer-usgstopo {
    background-image: url('../images/usgstopo.png');
}

#regular-layer-container {
    margin-left: 10px;
    margin-bottom: 10px;
    width: 300px;
    user-select: none;
}

.regular-layer-normal {
    width: 275px;
    border: 1px solid black;
    border-radius: 6px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    color: black;
    font-size: 15px;
    margin-bottom: 10px;
}

.regular-layer-normal > span {
    margin-left: 10px;
}

.regular-layer-normal > input {
    margin: 0;
}

.regular-layer-many {
    width: 255px;
    border: 1px solid black;
    border-radius: 6px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 9px;
    color: black;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.regular-layer-many > span {
    margin-left: 7px;
}

.regular-layer-many > input {
    margin: 0;
}

.regular-layer-normal-selected {
    border: 1px solid blue;
}

#custom-maps-main-container {
    margin: 10px 5px;
}

.custom-map-container {
    border: 2px solid black;
    color: black;
    margin-top: 15px;
}

.custom-map-container-title {
    margin-left: 6px;
}

.custom-map-container-header {
    font-size: 20px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}

.custom-map-container-main {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.custom-map-container-points {
    display: flex;
    flex-direction: column;
}

.custom-map-container-points select {
    font-size: 13px;
}

.custom-map-display-toggle {
    width: 25px;
    margin-left: 5px;
    cursor: pointer;
}

.custom-map-description {
    font-size: 12px;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.custom-map-show-points-button {
    width: 100%;
    border: none;
    background-color: grey;
    color: white;
    height: 25px;
}

.custom-map-point-control {
    display: flex;

}

.custom-map-show-points-button:hover {
    background-color: black;
}

.custom-map-point-edit-button {
    height: 17px;
    width: 17px;
    margin-right: 5px;
}

.custom-map-point-edit-button:hover {
    cursor: pointer;
}

.custom-map-point {
    margin-top: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-map-point-container {
    display: flex;
    align-items: center;
}

.custom-map-point-selected {
    border: 2px solid black;
}

.custom-map-point-icon {
    margin: 0 5px;
    height: 20px;
    min-width: 20px;
    object-fit: contain;
}

.custom-map-point-text {
    font-size: 13px;
}

.custom-map-point-text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.custom-map-edit-button {
   width: 22px;
   height: 22px;
   margin-right: 5px;
}

.custom-map-edit-button:hover {
    cursor: pointer;
}

.custom-map-add-button {
    color: black;
    padding: 10px;
    border: 1px solid black;
    font-size: 15px;
    border-radius: 8px;
}

.custom-map-add-button:hover {
    cursor: pointer;
    background-color: lightblue;
    border: 1px solid grey;
    color: white;
}

.no-custom-maps {
    height: 200px;
}

.settings-container {
    color: black;
    font-size: 13px;
}

.settings-container > input {
    vertical-align: middle;
}

.layers-container {
    color: black;
    font-size: 11px;
}