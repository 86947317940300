#modal {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    position: relative;
    height: 100%;
    width: 100%;
}

#modal-background {
    z-index: 1000;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: .7;
}

#modal-content {
    z-index: 1001;
    background-color: white;
    opacity: 1;
    width: 700px;
    height: 500px;
    margin-bottom: 100px;
    border-radius: 10px;
    color: black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#modal-form-content {
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
    border-bottom: 1px solid grey;
}

.modal-form-content-label {
    font-weight: bold;
    margin-bottom: 3px;
}

#modal-form-content > input {
    border-radius: 5px;
    border: 1px solid black
}

#modal-form-content > textarea {
    min-height: 80px;
    border-radius: 5px;
}

#modal-form-content > button {
    width: 30px;
    min-height: 30px;
}

.modal-form-control-buttons > button {
    width: 30px;
    min-height: 30px;
}

#modal-form-submit-button {
    width: 100px!important;
    border-radius: 5px;
    border: 1px solid black;
}

#modal-form-submit-button:hover {
    cursor: pointer;
    background-color: grey;
}

.modal-form-control-buttons {
    display: flex;
    justify-content: space-between;
}

#modal-form-delete-button {
    width: 100px!important;
    border-radius: 5px;
    border: 1px solid grey;
    background-color: red;
    color: white;
}

#modal-form-delete-button:hover {
    cursor: pointer;
    background-color: darkred;
}

.modal-form-list-button {
    margin-left: 10px;
    background-color: unset;
    border: none;
    padding: 0;
    font-weight: bold;
    font-size: 20px;
}

.modal-form-list-button:hover {
    cursor: pointer;
    color: blueviolet;
}

#modal-title {
    font-size: 20px;
    font-weight: bold;
}

#modal-close-button {
    z-index: 1002;
    width: 150px;
    height: 30px;
    background-color: lightgray;
    border: 1px solid black;
    border-radius: 5px;
    margin: 10px;
}

#modal-close-button:hover {
    background-color: gray;
    cursor: pointer;
}