/*Target div with id map, make it fill the entire screen without scrollbars*/
div#map {
  position: absolute;
  top: 80px; /* 80px is the height of the nav bar */
  bottom: 0;
  width: 100%;
}

#towerpopupstat {
  font-size: smaller;
  color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: block;
}

#towerpopuptext {
  color: #000000;
  z-index: 999;
  display: inline;
}

#rabbitearslink {
  color: #000000;
  z-index: 999;
  text-decoration: underline;
  display: inline;
}

#towerpopuptitle {
  font-size: small;
  color: #000000;
  z-index: 999;
  text-align: center;
  display: block;
}

#streetview {
  width: 80%;
  height: 80%;
  top: 10%;
    left: 10%;
    position: absolute;

  z-index: 1001;
  /*display: block;*/
}

#dimmer {
  display: none;
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
}

#closestreetview {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 0;
}
