div#rightclickpopup {
    padding: 0;
    /* break and wrap text */
    word-break: break-word;
    white-space: pre-line;
}

div#right-click-popup-content {
    z-index: 999;
}

text#rightclickpopup-routing-state {
    z-index: 999;
    font-weight: bold;
    font-size: 18px;
    color: black;
    top: 4px;
    margin-right: 50px;
}

text#custompopupname {
    z-index: 999;
    font-weight: bold;
    font-size: 16px;
    color: black;
    top: 4px;
}

text#custompopupdescription {
    z-index: 999;
    font-size: 13px;
    color: black;
    top: 24px;
}

text#routing-information {
    z-index: 9;
    color: black;
    position: absolute;
    font-size: 15px;
    width: 100%;
}

div#rightclickpopupexternallinks {
    margin-top: 5px;
}

div#rightclickpopupexternallinks a {
    font-size: 17px;
    color: black;
    margin-bottom: 10px;
}

div#rightclickpopupexternallinks text {
    font-size: 15px;
    color: black;
    margin-bottom: 10px;
    margin-right: 5px;
}


div#rightclickpopupbuttons {
/*    align right*/
/*    position: absolute;*/
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    z-index: 999;
}

button#rightclickpopupbutton {
    float: right;
    z-index: 999;
    display: block;
}

text#popupcoords {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: smaller;
    color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    text-align: center;
    display: block;
}

button#coordinatesbutton {
    /*width: 100%;*/
    line-height: 18px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    z-index: 999;
}


div#custompopupdetail {
    padding-top: 4px;
    padding-bottom: 3px;
    line-height: 1.2em;
    color: rgba(0, 0, 0, 0.6);
    z-index: 999;
}

input#custompopupname {
    font-weight: bold;
    font-size: 16px;
    color: black;
    width: 95%;
    margin-right: 20px;
}

textarea#custompopupdescription {
    font-size: 13px;
    color: black;
    width: 95%;
    resize: none;
}

div#custompopupselects {
    padding-bottom: 3px;
    padding-top: 3px;
    color: rgba(0, 0, 0, 0.6);
    z-index: 999;
    width: 95%;
}

select#custompopupcategory {
    font-size: 13px;
    color: black;
    width: 100%;
}

select#custompopupcolor {
    font-size: 13px;
    color: black;
    width: 100%;
}

table#custompopupinfotable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td, th {
    width: 25%;
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
    color: black;
}

tr:nth-child(even) {
    background-color: #cecece;
}


.rightclick-popup .mapboxgl-popup-content {
    background-color: #e6e6e6;
    padding-bottom: 0;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: #e6e6e6 !important;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: #e6e6e6 !important;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #e6e6e6 !important;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: #e6e6e6 !important;
}
